<template>
  <div>
    <In-Head :title="$t('sales.stock.index.title')" />

    <sub-header :title="$t('sales.stock.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-form-button
          v-if="$page.props?.permissions.canExport"
          icon="download"
          :route="$page.props?.routes.export"
          name="export"
          method="post"
        />
        <sm-button
          v-if="$page.props.permissions.canCreate"
          preset="add"
          @click="onCreate" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`sales.stock.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                {{ slotProps.data?.name }}
                <span
                  v-if="slotProps.data?.trashed"
                  class="text-bo-red">({{ $t('generic.deleted2') }})</span>
              </template>
              <template v-else-if="col === 'code'">
                {{ slotProps.data?.barcode }} /<br>
                {{ slotProps.data?.identification_number }}
              </template>
              <template v-else-if="col === 'amount'">
                <div class="whitespace-nowrap">
                  {{ slotProps.data?.amount.amount }}<br>
                  <span class="text-xxs">
                    {{ slotProps.data?.amount.remainder }}
                  </span>
                </div>
              </template>
              <template v-else-if="col === 'price'">
                <div class="whitespace-nowrap">
                  <template v-if="slotProps.data?.can_be_sold_independently">
                    <span class="text-xxs">{{ $t('stock.columns.price') }}:</span>
                    <strong>{{ slotProps.data?.price }} Ft</strong>
                    <span class="text-xxs">({{ $t('generic.abbr.gross') }})</span><br>
                  </template>
                  <template v-else>
                    <span class="text-xxs">{{ $t('stock.columns.price') }}:</span>
                    <strong>- Ft</strong><br>
                  </template>
                  <span
                    class="text-xxs">{{ $t('stock.columns.avg_weighted_price') }}</span> {{ slotProps.data?.weighted_avg_price }} Ft
                  <span class="text-xxs">({{ $t('generic.abbr.net') }})</span>
                  {{ slotProps.data?.weighted_avg_price_gross }} Ft <span
                    class="text-xxs">({{ $t('generic.abbr.gross') }})</span>
                </div>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-toggle-button
                  v-if="!slotProps.data?.trashed"
                  title="Műveletek"
                  :options="actions(slotProps)" />
                <template
                  v-if="!slotProps.data?.trashed">
                  <sm-button
                    v-if="slotProps.data?.permissions.canUpdate"
                    v-tippy="$t('generic.edit')"
                    preset="edit-icon"
                    @click="onUpdate(slotProps)" />
                  <sm-delete-button
                    v-if="slotProps.data?.permissions.canDelete"
                    :route="slotProps.data?.routes.destroy"
                    :name="`delete_stock_${slotProps.data?.id}`"
                  />
                </template>
                <sm-form-button
                  v-else
                  v-tippy="$t('generic.restore')"
                  icon="rotate-ccw"
                  :route="slotProps.data?.routes.restore"
                  :name="`restore_stock_${slotProps.data.id}`"
                  method="post"
                  confirm
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { router } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name", "brand", "code", "type", "amount", "warehouse_value", "price"
]);

const actions = (slotProps) => {
  return [
    {
      id: `product_warehouses_${slotProps.data?.id}`,
      label: window.trans("sales.stock.index.table.actions.product_warehouses"),
      to: slotProps.data?.routes.product_warehouses,
      icon: "home",
    },{
      id: `product_units_${slotProps.data?.id}`,
      label: window.trans("sales.stock.index.table.actions.product_units"),
      to: slotProps.data?.routes.product_units,
      icon: "box",
      visible: slotProps.data?.permissions.canListUnits,
    },{
      id: `product_units_${slotProps.data?.id}`,
      label: window.trans("sales.stock.index.table.actions.move_stock"),
      to: slotProps.data?.routes.move_stock,
      icon: "shuffle",
      visible: slotProps.data?.permissions.canMove,
    },{
      id: `stock_movements_${slotProps.data?.id}`,
      label: window.trans("sales.stock.index.table.actions.stock_movements"),
      to: slotProps.data?.routes.stock_movements,
      icon: "clock",
      visible: slotProps.data?.permissions.canMove,
    },{
      id: `edit_recipe_${slotProps.data?.id}`,
      label: window.trans("sales.stock.index.table.actions.edit_recipe"),
      to: slotProps.data?.routes.edit_recipe,
      icon: "book-open",
    }
  ];
};

const onUpdate = (slotProps) => {
  window.Livewire.emit("openModal", "club-stock.modal", {stockId: slotProps.data?.id});
};

const onCreate = () => {
  window.Livewire.emit("openModal", "club-stock.modal");
};

window.Livewire.on("inertiaRefresh", () => {
  router.reload({ only: ["table", "errors", "flash"] }, { preserveState: true });
});
</script>
