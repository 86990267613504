<template>
  <div>
    <In-Head :title="$t('reports.ticket_usages.title')" />

    <sub-header
      :title="$t('reports.ticket_usages.title')"
      :export-route="$page.props.routes.export"
      automatic-export
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <full-line-chart
      :title="$t('reports.ticket_usages.total')"
      :chart-data="chartData"
      :loading="!$page.props.table"
    />

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.ticket_usages.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template
                v-else-if="col === 'customer_group'">
                <span v-if="typeof(slotProps.data?.customer_group) === 'string'">{{ slotProps.data?.customer_group }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer_group" />
              </template>
              <template
                v-else-if="col === 'appointment'">
                <template v-if="slotProps.data?.appointment">
                  <p
                    v-if="slotProps.data?.appointment.service"
                    class="mb-0 font-bold">
                    {{ slotProps.data?.appointment.service }}
                  </p>
                  <p class="mb-0">
                    {{ useDateRangeFormat(slotProps.data?.appointment.from, slotProps.data?.appointment.to) }}
                  </p>
                </template>
                <template v-else>
                  <p>-</p>
                </template>
              </template>
              <template v-else-if="col === 'attended'">
                <sm-boolean-flag
                  v-if="typeof slotProps.data?.attended === 'boolean'"
                  :value="slotProps.data?.attended" />
                <span v-else>-</span>
              </template>
              <template v-else-if="col === 'created_at'">
                <p v-if="slotProps.data?.created_at">
                  {{ useDateFormat(slotProps.data?.created_at, 'long') }}
                </p>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, useDateRangeFormat } from "@/inertia/composables/global";
import { useRandomTailwindColor } from "@/inertia/composables/charts";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["customer", "customer_group", "ticket", "appointment", "attended", "created_at"];

const chartData = computed(() => {
  const stats = props.table?.stats ?? [];

  const sortedStats = [].concat(stats).sort(
    (a, b) => b.ticket_count - a.ticket_count
  );

  const values = sortedStats.map(i => parseInt(i.ticket_count));

  return {
    title: window.trans("reports.ticket_usages.total"),
    labels: sortedStats.map(s => s.name),
    data: values,
    colors: sortedStats.map((s, i) => useRandomTailwindColor(i)),
    subtitle: " db",
    summary: {
      quantity: values.reduce((sum, acc) => sum += acc, 0),
      subtitle: "db"
    }
  };
});
</script>
