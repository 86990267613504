<template>
  <div>
    <In-Head :title="$t('reports.customers_inside.title')" />

    <sub-header :title="$t('reports.customers_inside.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-form-button
          :title="$t('customers.actions.checkout_customer_inside.button')"
          :route="$page.props.routes.checkOutCheckedInCustomers"
          name="checkOutCheckedInCustomers"
          method="post"
          :confirm="$t('customers.actions.checkout_customer_inside.button')"
          :confirm-body="$t('customers.actions.checkout_customer_inside.description')"
          :accept-label="$t('customers.actions.checkout_customer_inside.button')"
        />
      </template>
    </sub-header>

    <div class="sm-row">
      <div class="sm-col xl:w-1/2 lg:w-8/12">
        <sm-card>
          <div class="flex flex-wrap justify-start space-y-4 lg:space-x-4 lg:space-y-0">
            <info-card
              v-for="type in infoArray"
              :key="type.id"
              large
              class="lg:!flex-none"
              :loading="!$page.props.table"
              :data="type" />
          </div>
        </sm-card>
      </div>
    </div>

    <h4 class="mb-4">
      {{ $t('reports.customers_inside.subtitle') }}
    </h4>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.customers_inside.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template
                v-else-if="col === 'created_at'">
                {{ useDateFormat(slotProps.data?.created_at, 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import InfoCard from "@/inertia/pages/reports/_partials/InfoCard.vue";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const totalCheckinsToday = computed(() => props.table?.stats?.totalCheckinsToday);
const totalCustomersToday = computed(() => props.table?.stats?.totalCustomersToday);

const infoArray = ref([
  {
    color: "teal",
    icon: "user-check",
    name: window.trans("reports.customers_inside.info.customers_inside"),
    data: totalCustomersToday,
  },
  {
    color: "pink",
    icon: "user-check",
    name: window.trans("reports.customers_inside.info.checkins_today"),
    data: totalCheckinsToday,
  }
]);

const tableFields = ["customer", "created_at", "ticket", "balance"];
</script>
