<template>
  <div>
    <In-Head :title="$t('sales.shifts.show.title')" />

    <sub-header
      :title="$t('sales.shifts.show.title')"
      crud-filter-portal="transactionTable"
      crud-filter-position="left">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card>
      <div class="items-center justify-between sm:flex">
        <div>
          <h4 class="mb-1.5">
            #{{ shift.id }}
          </h4>
          <p class="mb-0">
            <strong>{{ $t('sales.shifts.show.summary.time') }}:</strong> {{ useDateFormat(shift.from, 'long') }} -  {{ useDateFormat(shift.to, 'long') }}
          </p>
          <p class="mb-0">
            <strong>{{ $t('sales.shifts.show.summary.receptionist') }}:</strong> {{ shift.receptionist ?? '-' }}
          </p>
          <p class="mb-0">
            <strong>{{ $t('sales.shifts.show.summary.cash_register') }}:</strong> {{ shift.cash_register }}
          </p>
        </div>
        <div v-if="shift.closing_activity_exists && shift.permissions.download_pdf">
          <sm-button
            :href="shift.routes.download_pdf"
            icon="download"
            old-link
            target="_blank"
            :title="$t('sales.shifts.show.download_pdf')"
            size="xs" />
        </div>
      </div>
    </sm-card>

    <sm-card>
      <cash-register-report
        v-if="shift.closed"
        :cash-register-payouts="$page.props.cashRegisterPayouts"
        :shift="shift" />
      <sm-alert
        v-else
        no-margin
        color="light">
        {{ $t('sales.shifts.show.shift_not_closed') }}
      </sm-alert>
    </sm-card>

    <div class="mb-4">
      <portal-target name="transactionTable" />
    </div>

    <sm-card no-padding>
      <transaction-table
        :table="table"
        :sorts="sorts"
        :table-fields="tableFields" />
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

import TransactionTable from "../../_partials/TransactionTable.vue";
import CashRegisterReport from "./_partials/CashRegisterReport.vue";

const props = defineProps({
  shift: { type: Object, required: true, default: null },
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["paid", "id", "payer", "amount", "status", "payment_type", "locked_at", "paid_at"]);

const shift = ref(props.shift);
</script>
