<template>
  <settings-layout>
    <in-head :title="$t('sales.settings.title')" />

    <sales-settings
      :form="form"
      :settings="settings"
      @onSubmit="onSubmit" />

    <solarium
      :form="form"
      :settings="settings"
      @onSubmit="onSubmit" />
  </settings-layout>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import SalesSettings from "./components/SalesSettings.vue";
import Solarium from "./components/Solarium.vue";

const props = defineProps({
  settings: { type: Object, required: true, default:  null },
});

const page = usePage();

// build the form
const form = useForm({
  shift_report_recipients: props.settings.shift_report_recipients,
  employee_pricing_enabled: props.settings.employee_pricing_enabled,
  employee_pricing_percentage: props.settings.employee_pricing_percentage,
  exclude_invoiced_transactions_from_shift_report: props.settings.exclude_invoiced_transactions_from_shift_report,
  accessory_only_for_checked_in_customers: props.settings.accessory_only_for_checked_in_customers,
  shift_report_cash_register_all_day_cash_flow: props.settings.shift_report_cash_register_all_day_cash_flow,
  show_service_fee_field: props.settings.show_service_fee_field,
  first_purchase_in_club: props.settings.first_purchase_in_club,
  minimum_balance_topup_amount: props.settings.minimum_balance_topup_amount,
  solarium_product: props.settings.solarium_product,
  solarium_min_value: props.settings.solarium_min_value,
  solarium_max_value: props.settings.solarium_max_value,
  ticket_gifting_enabled: props.settings.ticket_gifting_enabled,
});

const onSubmit = () => {
  form.post(page.url);
};

provide("form", form);
</script>
