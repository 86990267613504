import CreateElementHelpers from "./CreateElementHelpers";

export default {
  mixins: [CreateElementHelpers],
  props: {
    data: { type: Object, default: () => ({}) },
    fixed: { type: Boolean, required: false, default: false }
  },
  computed: {
    serializedFilter() {
      return JSON.stringify({
        field: this.data.filter.field,
        id: this.data.id,
        ...this.filterData,
      });
    },
    icon() {
      if (!this.data.filter.icon) {
        return this.defaultIcon;
      }

      return this.data.filter.icon.length > 0 ?
        this.data.filter.icon :
        this.defaultIcon;
    },
    displayableValue() {
      return null;
    },
    labelText() {
      const label = this.data.filter.label || this.data.filter.field;
      return label.includes(".") ? this.$t(label) : label;
    },
  },
  created() {
    // eslint-disable-next-line vue/no-mutating-props
    this.data.data = {
      ...this.defaults,
      ...this.data.data,
    };
  },
  methods: {
    removeFilter(event) {
      this.$emit("remove", event);
    },
    renderAbstract(defaultSlot = null) {
      const labelColSlot = this.$slots["label-col"] || [
        this.createDiv("sm-col flex-1", [
          this.createLabel(this.labelText),
        ]),
      ];

      const removeColSlot = this.$slots["remove-col"] || [
        this.createDiv("sm-col flex-grow-0", [
          this.renderHiddenForValue(),
          this.$createElement("i", {
            staticClass: "fa fa-minus-circle text-bo-red col-form-label",
            on: { click: this.removeFilter }
          }),
        ]),
      ];

      return this.createDiv("crud-filter sm-row items-center", [
        labelColSlot,
        defaultSlot || this.$slots["default"],
        removeColSlot,
      ]);
    },
    renderHiddenForValue() {
      return this.createInput([this, "serializedFilter"], "hidden", "filters[]");
    }
  },
};
