<script>
import { format } from "date-fns";
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        operator: this.data.filter.options.defaultOperator,
        term: this.format(new Date()),
      },

      availableOperators: ["=", "<>", "<", "<=", ">", ">="],
      defaultIcon: "calendar",
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
        operator: this.data.data.operator,
      };
    },
    displayableValue() {
      const label = format(new Date(this.data.data.term), "yyyy. MM. dd.");

      return `: ${label}`;
    }
  },
  methods: {
    format(date) {
      return format(new Date(date), "yyyy-MM-dd");
    }
  },
  render() {
    return this.data.filter.options.featured ?
      this.createDiv("flex items-center w-full lg:w-auto", [
        this.createDiv("mr-2", [
          this.createLabel(this.$t(this.data.filter.label || this.data.filter.field), {default: true}),
        ]),
        this.createDiv("sm-input-group !mb-0 small w-full lg:w-auto", [
          this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]),
          this.createDiv("sm-input-group-extra", [
            this.createSelect(
              [this.data.data, "operator"],
              this.availableOperators.map(t => ({ label: "crud_filter." + t, value: t })),
              "operatorSelect", { class: "sm-select-sm w-14 text-center leading-none" }
            ),
          ]),
          this.createDatePicker([this.data.data, "term"], "text", "input"),
        ]),
        this.renderHiddenForValue(),
      ]) : this.renderAbstract([
        this.createDiv("sm-col w-2/12", [
          this.createSelect(
            [this.data.data, "operator"],
            this.availableOperators.map(t => ({ label: "crud_filter." + t, value: t }))
          ),
        ]),
        this.createDiv("sm-col w-6/12", [
          this.createDiv("sm-input-group", [
            this.createDiv("sm-input-group-prepend", [
              this.createIcon(this.icon)
            ]),
            this.createDatePicker([this.data.data, "term"], "text", "input"),
          ]),
        ]),
      ]);
  },
};
</script>
