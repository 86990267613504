<script>
import { useDateFormat } from "@/inertia/composables/global";
import { addDays, addWeeks, endOfISOWeek, endOfMonth, formatISO, startOfISOWeek, startOfMonth } from "date-fns";
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      firstLoaded: false,
      presetChanged: false,
      defaults: {
        operator: "=",
        term: this.data.filter.options.defaultTerm ?? {
          start: null,
          end: null
        },
      },

      presetModel: {
        term: this.data.filter.options.default ?? null,
      },

      ranges: [
        {
          value: "unique",
          label: "filters.date_range.unique"
        },
        {
          value: "this_week",
          label: "filters.date_range.this_week"
        },
        {
          value: "past_week",
          label: "filters.date_range.past_week"
        },
        {
          value: "past_30_days",
          label: "filters.date_range.past_30_days"
        },
        {
          value: "last_month",
          label: "filters.date_range.last_month"
        },
        {
          value: "this_month",
          label: "filters.date_range.this_month"
        }
      ],

      defaultIcon: "calendar",
    };
  },
  computed: {
    filterData() {
      return {
        term: {
          start: this.data.data.term ? useDateFormat(this.data.data.term.start, "form") : null,
          end: this.data.data.term ? useDateFormat(this.data.data.term.end, "form") : null,
        },
        preset: this.presetModel.term,
      };
    },
    displayableValue() {
      const label = this.data.data.term ? `${useDateFormat(this.data.data.term.start)} - ${useDateFormat(this.data.data.term.end)}` : null;

      return `: ${label}`;
    }
  },
  watch: {
    data: {
      handler() {
        if (this.firstLoaded && !this.presetChanged) {
          this.presetModel.term = "unique";
        }
        this.firstLoaded = true;
      },
      deep: true
    },
    presetModel: {
      handler(val) {
        this.presetChanged = true;
        if (val.term === "this_week") {
          this.changeData(startOfISOWeek(new Date()), endOfISOWeek(new Date()));
        } else if (val.term === "past_week") {
          this.changeData(startOfISOWeek(addDays(new Date(), -7)), endOfISOWeek(addWeeks(new Date(), -1)));
        } else if (val.term === "past_30_days") {
          this.changeData(addDays(new Date(), -30), new Date());
        } else if (val.term === "last_month") {
          this.changeData(startOfMonth((new Date()).setDate(0)), endOfMonth((new Date()).setDate(0)));
        } else if (val.term === "this_month") {
          this.changeData(startOfMonth((new Date())), endOfMonth((new Date())));
        }
      },
      deep: true
    },
  },
  methods: {
    changeData(start, end) {
      this.data.data.term = {
        start: formatISO(start),
        end: formatISO(end)
      };
      setTimeout(() => {
        this.presetChanged = false;
      }, 500);
    },
  },
  render() {
    return this.data.filter.options.featured ?
      this.createDiv("flex items-center w-full lg:w-auto", [
        this.createDiv("mr-2", [
          this.createLabel(this.$t(this.data.filter.label || this.data.filter.field), {default: true}),
        ]),
        this.createDiv("sm-input-group !mb-0 small w-full lg:w-auto", [
          this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]),
          this.createDiv("sm-input-group-extra", [
            this.createSelect([this.presetModel, "term"], this.ranges, "rangeSelect", { class: "sm-select-sm w-auto" }),
          ]),
          this.createDateRangePicker([this.data.data, "term"], "text", "input", {featured: true}),
        ]),
        this.renderHiddenForValue(),
      ]) : this.renderAbstract([
        this.createDiv("sm-col w-9/12", [
          this.createDiv("sm-row", [
            this.createDiv("sm-col w-[165px]", [
              this.createSelect([this.presetModel, "term"], this.ranges, "rangeSelect"),
            ]),
            this.createDiv("sm-col flex-1", [
              this.createDiv("sm-input-group", [
                this.createDiv("sm-input-group-prepend", [
                  this.createIcon(this.icon)
                ]),
                this.createDateRangePicker([this.data.data, "term"], "text", "input"),
              ]),
            ]),
          ]),
        ]),
      ]);
  },
};
</script>
