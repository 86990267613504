<template>
  <settings-layout>
    <in-head :title="$t('lockers.settings.title')" />

    <form @submit.prevent="onSubmit()">
      <sm-card
        :header-title="$t('lockers.settings.title')">
        <sm-input
          v-model="form.time_to_allow_locker_management_after_checkout"
          name="time_to_allow_locker_management_after_checkout"
          :label="$t('lockers.settings.time_to_allow_locker_management_after_checkout.label')"
          :help="$t('lockers.settings.time_to_allow_locker_management_after_checkout.help')"
          inline
          type="number"
          min="0"
          step="1"
          required
        >
          <template #append>
            perc
          </template>
        </sm-input>
      </sm-card>
      <sm-card>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </sm-card>
    </form>
  </settings-layout>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";

const page = usePage();

const props = defineProps({
  settings: { type: Object, required: true, default: null },
});

const form = useForm({
  time_to_allow_locker_management_after_checkout: props.settings.time_to_allow_locker_management_after_checkout,
});

const onSubmit = () => {
  form.post(page.url);
};

provide("form", form);
</script>
