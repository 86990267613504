<template>
  <div>
    <sm-button
      v-tippy="props.isOpen ? $t('lockers.index.closing') : $t('lockers.index.opening')"
      size="icon"
      :icon="props.isOpen ? 'lock' : 'unlock'"
      @click="toggleModalVisibility" />
    <sm-modal
      :modal-is-visible="modalOpen"
      :modal-title="props.isOpen ? $t('lockers.open_close_modal.title_close') : $t('lockers.open_close_modal.title_open')"
      @toggleModalVisibility="toggleModalVisibility">
      <p class="mb-2">
        {{ $t('lockers.index.change_locker_alert') }}
      </p>
      <sm-select
        v-if="!isOpen"
        v-model="selectedReason"
        class="mt-4"
        :label="$t('lockers.open_close_modal.reason.title')"
        :options="openOptions" />
      <div>
        <sm-alert
          v-if="loading"
          color="warning"
        >
          {{ props.isOpen ? $t('lockers.open_close_modal.loading_close') : $t('lockers.open_close_modal.loading_open') }}
        </sm-alert>
        <sm-alert
          v-if="successfulAction"
          color="success"
        >
          {{ props.isOpen ? $t('lockers.open_close_modal.success_close') : $t('lockers.open_close_modal.success_open') }}
        </sm-alert>
        <sm-alert
          v-if="errorMessage"
          color="danger"
        >
          {{ errorMessage }}
        </sm-alert>
      </div>
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <sm-button
          color="primary"
          :loading="loading"
          :disabled="submitted"
          :title="props.isOpen ? $t('lockers.index.closing') : $t('lockers.index.opening')"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </div>
</template>

<script setup>
import axios from "axios";

const props = defineProps({
  isOpen: { type: Boolean, default: false, required: true },
  updateEndpoint: { type: String, default: "", required: true },
  locker: { type: Number, required: true },
});

const emit = defineEmits(["closed"]);
const selectedReason = ref(null);

const modalOpen = ref(false);
function toggleModalVisibility() {
  modalOpen.value = !modalOpen.value;

  if (modalOpen.value) {
    loading.value = false;
    submitted.value = false;
    successfulAction.value = false;
    errorMessage.value = null;
    selectedReason.value = null;
  } else {
    emit("closed");
  }
}

const loading = ref(false);
const submitted = ref(false);

const successfulAction = ref(false);
const errorMessage = ref(null);

const openOptions = ref([
  {
    id: "phone_inside",
    label: window.trans("lockers.open_close_modal.reason.options.phone_inside")
  },
  {
    id: "cant_open",
    label: window.trans("lockers.open_close_modal.reason.options.cant_open")
  }
]);


async function onSubmit() {
  try {
    errorMessage.value = null;
    loading.value = true;
    successfulAction.value = false;
    await axios.post(props.updateEndpoint, {
      reason: selectedReason.value ? selectedReason.value.id : null
    });

    submitted.value = true;
    successfulAction.value = true;
  } catch (e) {
    errorMessage.value = e.response?.data?.message ?? e.message;
    submitted.value = false;
  } finally {
    loading.value = false;
  }
}

</script>
