<template>
  <div>
    <In-Head :title="$t('reports.new_customers.title')" />

    <sub-header
      :title="$t('reports.new_customers.title')"
      :export-route="$page.props.routes.export"
      automatic-export
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <div class="flex max-w-[240px]">
      <sm-card
        :header-title="stat.title"
        :header-info="stat.tooltip"
        class="flex-1 !pt-6 !px-[1.875rem]"
        v2>
        <div>
          <p class="flex items-center text-xl font-semibold leading-8 text-bo-blue">
            {{ useNumberFormat(stat.amount) }}
            <span
              v-if="stat.helper"
              class="ml-1 text-sm text-bo-gray-400">{{ stat.helper }}</span>
          </p>
        </div>
      </sm-card>
    </div>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.new_customers.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'name'">
                <sm-avatar
                  :user="slotProps.data?.name" />
              </template>
              <template
                v-else-if="col === 'last_checkin' || col === 'created_at'">
                {{ useDateFormat(slotProps.data?.[col], 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, useNumberFormat } from "@/inertia/composables/global";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["name", "email", "phone", "last_checkin", "season_ticket", "created_at"];

const stat = computed(() => {
  return {
    title: "Új vendégek összesen",
    tooltip: "Az összeg a megadott szűrők alapján számolt értéket mutatja.",
    amount: props.table?.new_customer_count ?? 0,
    helper: "fő",
  };
});
</script>
