<template>
  <sm-card>
    <template #header>
      <div class="flex items-center justify-between mb-3">
        <p class="font-bold">
          {{ chartData.title }}
        </p>
        <div
          v-if="categories?.length"
          class="bg-bo-gray-100/50 rounded-[0.75rem] p-1">
          <button
            v-for="category in categories"
            :key="category.id"
            type="button"
            class="rounded-lg bg-transparent text-bo-gray-500 py-2.5 px-4 font-medium transition-colors"
            :class="{'!bg-bo-blue !text-white': category.id === activeCategory}"
            @click="changeActiveCategory(category.id)">
            {{ category.name }}
          </button>
        </div>
      </div>
      <div class="space-x-1 text-xl font-bold leading-8 text-bo-blue">
        <template v-if="loading">
          <div class="inline-block w-32 h-5 rounded bg-bo-gray-200 animate-pulse" />
        </template>
        <span v-else>
          {{ useNumberFormat(chartData.summary.quantity) }}
        </span>
        <span class="text-sm font-normal leading-8 text-bo-gray-400">{{ chartData.summary.subtitle }}</span>
      </div>
    </template>

    <div class="pt-2.5">
      <div>
        <div class="flex w-full h-5 mb-3 overflow-hidden rounded-xl bg-brand-blue-10">
          <span
            v-if="loading"
            class="w-full animate-pulse bg-bo-gray-200"
          />
          <span
            v-for="(data, index) in chartData.data"
            v-else
            :key="data"
            v-tippy="{allowHTML: true, content: `${chartData.labels[index]}<br>${useNumberFormat(data)} ${chartData.subtitle}`}"
            class="block cursor-pointer"
            :style="`width: ${calculateWidth(data)}%;background-color: ${colors[index]};`" />
        </div>
        <div
          v-if="!loading"
          class="flex w-full mb-5">
          <template
            v-for="(data, index) in chartData.data"
            :key="data">
            <div
              v-if="calculateWidth(data) >= 6.5"
              class="block"
              :style="`width: ${calculateWidth(data)}%`">
              <div
                class="p-1.5 border inline-flex rounded-[4px] relative"
                :style="`border-color: ${colors[index]};`">
                <span
                  :style="`background-color: ${colors[index]};`"
                  class="absolute top-0 left-0 w-full h-full opacity-50" />
                <span class="relative text-xs font-bold whitespace-nowrap">
                  {{ useNumberFormat(data) }} {{ chartData.subtitle }}
                </span>
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="!loading"
          class="flex flex-wrap">
          <p
            v-for="(data, index) in chartData.data"
            :key="data"
            class="flex items-center mr-4 whitespace-nowrap">
            <span
              class="block min-w-[0.375rem] h-1.5 bg-bo-blue rounded-full ml-1.5 mr-2"
              :style="`background-color: ${colors[index]}`" />
            {{ chartData.labels[index] }}
            <span
              v-if="calculateWidth(data) < 6.5"
              class="ml-1 text-xs text-bo-gray-500">
              ({{ data }} {{ chartData.subtitle }})
            </span>
          </p>
        </div>
      </div>
    </div>
  </sm-card>
</template>

<script setup>
import { useNumberFormat } from "@/inertia/composables/global";

const props = defineProps({
  chartData: {
    type: Object, required: true, default() {
      return {
        title: "",
        labels: [],
        data: [],
        colors: [],
        subtitle: "",
        quantity: {
          title: "",
          subtitle: ""
        }
      };
    }
  },
  activeCategory: { type: String, required: false, default: null },
  categories: { type: Array, required: false, default: null },
  loading: { type: Boolean, required: false, default: false },
});

const emits = defineEmits(["update:activeCategory"]);

const sumOfData = computed(() => props.chartData?.summary.quantity);

const changeActiveCategory = (id) => {
  emits("update:activeCategory", id);
};

const colors = computed(() => props.chartData.colors);

const calculateWidth = (data) => {
  if (sumOfData.value <= 0) {
    return 0;
  }

  return (data / sumOfData.value) * 100;
};
</script>
