import { createInertiaApp } from "@inertiajs/vue3";
import { createApp, h } from "vue";

// plugins
import hljsVuePlugin from "@highlightjs/vue-plugin";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import OverlayPanel from "primevue/overlaypanel";
import ProgressBar from "primevue/progressbar";
import Ripple from "primevue/ripple";
import { createStore } from "vuex";

hljs.registerLanguage("javascript", javascript);

// inerta
import { Head, Link } from "@inertiajs/vue3";
Head.compatConfig = { RENDER_FUNCTION: false };
Link.compatConfig = { RENDER_FUNCTION: false };

// layouts
import SettingsLayout from "./inertia/layouts/SettingsLayout.vue";
import SiteLayout from "./inertia/layouts/SiteLayout.vue";

// components
import SmAlertDialog from "./inertia/components/base/SmAlertDialog.vue";
import SmAnchorPoint from "./inertia/components/base/SmAnchorPoint.vue";
import SmAvatar from "./inertia/components/base/SmAvatar.vue";
import SmBooleanFlag from "./inertia/components/base/SmBooleanFlag.vue";
import SmBreadcrumbs from "./inertia/components/base/SmBreadcrumbs.vue";
import SmButton from "./inertia/components/base/SmButton.vue";
import SmCard from "./inertia/components/base/SmCard.vue";
import SmConfirmDialog from "./inertia/components/base/SmConfirmDialog.vue";
import SmModal from "./inertia/components/base/SmModal.vue";
import SmToggleButton from "./inertia/components/base/SmToggleButton.vue";
import SmConfirmButton from "./inertia/components/base/buttons/SmConfirmButton.vue";
import SmDeleteButton from "./inertia/components/base/buttons/SmDeleteButton.vue";
import SmFormButton from "./inertia/components/base/buttons/SmFormButton.vue";
import EmptyTable from "./inertia/components/base/table/EmptyTable.vue";
import SmTable from "./inertia/components/base/table/SmTable.vue";

import SmCrudFilter from "./inertia/components/filter/SmCrudFilter.vue";

import SmAlert from "./inertia/components/forms/SmAlert.vue";
import SmAsyncSelect from "./inertia/components/forms/SmAsyncSelect.vue";
import SmAsyncSelect2 from "./inertia/components/forms/SmAsyncSelect2.vue";
import SmCheckbox from "./inertia/components/forms/SmCheckbox.vue";
import SmCopyCode from "./inertia/components/forms/SmCopyCode.vue";
import SmDatePicker from "./inertia/components/forms/SmDatePicker.vue";
import SmDatePicker2 from "./inertia/components/forms/SmDatePicker2.vue";
import SmDateRangePicker from "./inertia/components/forms/SmDateRangePicker.vue";
import SmFile from "./inertia/components/forms/SmFile.vue";
import SmInput from "./inertia/components/forms/SmInput.vue";
import SmLabel from "./inertia/components/forms/SmLabel.vue";
import SmRadio from "./inertia/components/forms/SmRadio.vue";
import SmRadioGroup from "./inertia/components/forms/SmRadioGroup.vue";
import SmSelect from "./inertia/components/forms/SmSelect.vue";
import SmTimePicker from "./inertia/components/forms/SmTimePicker.vue";
import UserPickerRow from "./inertia/components/forms/_partials/UserPickerRow.vue";

import DoughnutChart from "./inertia/components/charts/DoughnutChart.vue";
import FullLineChart from "./inertia/components/charts/FullLineChart.vue";

import SmNavTabs from "./inertia/components/tabs/SmNavTabs.vue";

import SubHeader from "./inertia/components/pages/common/SubHeader.vue";

import SmDatatable from "./inertia/components/primevue/SmDatatable.vue";

import StyleguideElement from "./inertia/components/styleguide/StyleguideElement.vue";

// primevue tailwind config
import SportmatePrimevueConfig from "./inertia/primevue.tailwind";

// global vue config
import GlobalVue from "./shared/GlobalVue";

// Local imports
import appointmentModule from "./backoffice/modules/appointments/store";
import cafeteriaModule from "./backoffice/modules/cafeteria/store";
import "./shared/Translation";
import { dialogStore } from "./shared/plugins/ConfirmDialog";

PrimeVue.compatConfig = { MODE: 3 };

if (document.querySelector("#app")) {
  createInertiaApp({
    title: title => `SportMate ${title ? " - " + title : ""}`,
    resolve: name => {
      const pages = import.meta.glob("./inertia/pages/**/*.vue", { eager: true });
      let page = pages[`./inertia/pages/${name}.vue`];
      page.default.layout = page.default.layout || SiteLayout;
      return pages[`./inertia/pages/${name}.vue`];
    },
    setup({ el, App, props, plugin }) {
      const app = createApp({
        setup() {
          return () => h(App, props);
        }
      });

      app.use(plugin);

      // Initialize Primevue
      app.use(PrimeVue, {
        ripple: true,
        unstyled: true,
        pt: SportmatePrimevueConfig
      });
      app.use(ConfirmationService);
      app.directive("ripple", Ripple);
      app.use(hljsVuePlugin);

      // Initialize primevue components
      app.component("DataTable", DataTable);
      app.component("Column", Column);
      app.component("ProgressBar", ProgressBar);
      app.component("ColorPicker", ColorPicker);
      app.component("Dropdown", Dropdown);
      app.component("ConfirmDialog", ConfirmDialog);
      app.component("OverlayPanel", OverlayPanel);

      // Initialize Sportmate components
      app.component("SettingsLayout", SettingsLayout);

      app.component("SmButton", SmButton);
      app.component("SmCard", SmCard);
      app.component("SmAvatar", SmAvatar);
      app.component("SmBreadcrumbs", SmBreadcrumbs);
      app.component("SmToggleButton", SmToggleButton);
      app.component("SmModal", SmModal);
      app.component("SmDeleteButton", SmDeleteButton);
      app.component("SmFormButton", SmFormButton);
      app.component("SmConfirmButton", SmConfirmButton);
      app.component("SmConfirmDialog", SmConfirmDialog);
      app.component("SmAlertDialog", SmAlertDialog);
      app.component("EmptyTable", EmptyTable);
      app.component("SmAnchorPoint", SmAnchorPoint);
      app.component("SmBooleanFlag", SmBooleanFlag);

      app.component("SmCrudFilter", SmCrudFilter);

      app.component("SmLabel", SmLabel);
      app.component("SmInput", SmInput);
      app.component("SmFile", SmFile);
      app.component("SmCheckbox", SmCheckbox);
      app.component("SmRadio", SmRadio);
      app.component("SmRadioGroup", SmRadioGroup);
      app.component("SmDatePicker", SmDatePicker);
      app.component("SmDateRangePicker", SmDateRangePicker);
      app.component("SmDatePickerDate", SmDatePicker2);
      app.component("SmTimePicker", SmTimePicker);
      app.component("SmSelect", SmSelect);
      app.component("SmAsyncSelect", SmAsyncSelect);
      app.component("SmAsyncSelect2", SmAsyncSelect2);
      app.component("SmAlert", SmAlert);
      app.component("SmCopyCode", SmCopyCode);
      app.component("UserPickerRow", UserPickerRow);
      app.component("SmTable", SmTable);

      app.component("DoughnutChart", DoughnutChart);
      app.component("FullLineChart", FullLineChart);

      app.component("SubHeader", SubHeader);

      app.component("SmDatatable", SmDatatable);

      app.component("SmNavTabs", SmNavTabs);

      app.component("StyleguideElement", StyleguideElement);

      app.component("InHead", Head);
      app.component("InLink", Link);

      // Initialize directives
      GlobalVue.registerDirectives(app);

      // Initialize components
      GlobalVue.registerComponents(app);

      // Initialize composables
      GlobalVue.registerComposables(app);

      // Initialize Vuex store
      const store = createStore({
        modules: {
          appointments: appointmentModule,
          cafeteria: cafeteriaModule,
          dialog: dialogStore,
        },
      });

      // Global Vue properties
      app.config.globalProperties.$store = store;
      app.config.globalProperties.$t = window.trans;
      app.config.compilerOptions.whitespace = "preserve";

      app.mount(el);
    },
  });
}
