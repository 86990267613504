<template>
  <div>
    <In-Head :title="$t('reports.active_customers.title')" />

    <sub-header
      :title="$t('reports.active_customers.title')"
      :export-route="$page.props.routes.export"
      automatic-export
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <div class="px-2 pt-2 pb-3 m-2 border-b border-bo-gray-200">
        <p><strong> {{ $t('reports.active_customers.total') }}</strong>: <span class="ml-2">{{ $page.props.table?.stats?.totalActiveCustomers }}</span></p>
      </div>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.active_customers.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template
                v-else-if="col === 'is_checkin'">
                <sm-boolean-flag :value="slotProps.data?.is_checkin" />
              </template>
              <template
                v-else-if="col === 'birth_date'">
                {{ useDateFormat(slotProps.data?.[col]) }}
              </template>
              <template
                v-else-if="col === 'created_at' || col === 'last_checkin'">
                {{ useDateFormat(slotProps.data?.[col], 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
  totalActiveCustomers: { type: [String, Number], required: false, default: null },
});

const tableFields = ["customer", "is_checkin", "email", "birth_date", "gender", "balance", "created_at", "last_checkin"];
</script>
