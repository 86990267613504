<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        :row-class="({ is_new }) => is_new ? '!bg-bo-blue-custom-25 hover:!bg-bo-blue-custom-50 font-semibold': null"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`export_config.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'progress'">
                {{ progressString(slotProps.data?.completed_jobs, slotProps.data?.job_count) }}
              </template>
              <template v-if="col === 'name'">
                {{ slotProps.data?.[col] }}
                <vue-feather
                  v-if="slotProps.data?.scheduled"
                  v-tippy="'Ütemezett'"
                  type="clock"
                  class="mr-1"
                  size="12"
                />
                <vue-feather
                  v-if="slotProps.data?.filters"
                  v-tippy="{
                    allowHTML: true,
                    content: formatFilters(slotProps.data)
                  }"
                  type="filter"
                  class="mr-1"
                  size="12"
                />
              </template>
              <template v-else-if="col === 'notified_at'">
                <template v-if="slotProps.data?.[col]">
                  {{ useDateFormat(slotProps.data?.[col], 'long') }}
                </template>
                <template v-else>
                  <sm-boolean-flag :value="false" />
                </template>
              </template>
              <template v-else-if="col === 'download_url'">
                <a
                  v-if="slotProps.data?.download_url"
                  class="text-bo-blue"
                  target="_blank"
                  :href="slotProps.data?.[col]"
                  @click="refresh">
                  {{ $t('export_config.values.download') }}
                </a>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>
<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { router } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name",
  "progress",
  "download_url",
  "notified_at",
  "created_by",
]);

function percentage(value, total) {
  if (total === 0) {
    return 100;
  }

  if (!total || !value) {
    return 0;
  }

  return ((value / total) * 100);
}

function progressString(value, total) {
  return `${percentage(value, total).toFixed(2)}%`;
}

function refresh() {
  setTimeout(() => {
    router.reload({only: ["table"]}, { preserveState: true });
  }, 500);
}

function getDateLabelForSchedule(interval) {
  if (! interval?.from) {
    return "";
  }

  return `${useDateFormat(interval.from)} - ${useDateFormat(interval.to)}`;
}

function formatFilters(data) {
  return [`Időszak: ${getDateLabelForSchedule(data?.schedule_interval)}`]
    .concat(data.filters.map(o => o.field + o.label))
    .filter(label => label.length > 0)
    .join("<br>");
}
</script>
