<script>
import { toRaw } from "vue";
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {},
      options: this.data.filter?.options?.options || [],

      preset: null,
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term
      };
    },
    displayableValue() {
      let label = "";

      if (this.data.data.term) {
        if (this.usingGroups) {
          const values = Object.values(toRaw(this.availableOptions));
          const newValues = values.flatMap(values => {
            return Object.keys(values)
              .map(key => {
                return { value: key, label: values[key] };
              });
          });

          label = newValues.find(o => o.value === this.data.data.term)?.label;
        } else if (Array.isArray(this.data.data.term)) {
          label = this.data.data.term.map(data => {
            const option = this.availableOptions.find(e => e.value === data);

            return option.label ?? "-";
          }).join(", ");
        } else {
          label = this.availableOptions.find(o => o.value === this.data.data.term)?.label;
        }
      }

      return `: ${label ?? "-"}`;
    },
    availableOptions() {
      let options = [];

      if (this.data.filter?.options?.optionGroups) {
        options = this.data.filter?.options?.optionGroups;
      } else {
        options = Array.isArray(this.options)
          ? this.options
          : Object.entries(this.options).map(([value, label]) => ({ value, label }));
      }

      return options;
    },
    usingGroups() {
      return this.data.filter?.options?.optionGroups;
    }
  },
  render() {
    const creator = this.data.filter.options.single ? this.createSelect : this.createMultiSelect;

    return this.data.filter.options.featured ?
      this.createDiv("flex items-center w-full lg:w-auto", [
        this.createDiv("mr-2", [
          this.createLabel(this.$t(this.data.filter.label || this.data.filter.field), {default: true}),
        ]),
        this.createDiv("sm-input-group !mb-0 small w-full lg:w-auto", [
          this.icon ? this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]) : null,
          creator([this.data.data, "term"], this.availableOptions, "", { class: "sm-select-sm w-auto" }, this.usingGroups),
        ]),
        this.renderHiddenForValue(),
      ]) : this.renderAbstract([
        this.createDiv("sm-col w-8/12", [
          this.createDiv("sm-input-group", [
            this.icon ? this.createDiv("sm-input-group-prepend", [
              this.createIcon(this.icon)
            ]) : null,
            creator([this.data.data, "term"], this.availableOptions, "", [], this.usingGroups),
          ]),
        ]),
      ]);
  },
};
</script>
