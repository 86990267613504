<template>
  <div>
    <sm-toggle-button
      icon="download"
      :options="exportButtonOptions"
      title="Export" />
    <sm-modal
      :modal-is-visible="exportModal"
      :modal-title="$t('export.automatic_export.modal.title')"
      @toggleModalVisibility="toggleExportModal">
      <div v-auto-animate>
        <template v-if="scheduledExports.length">
          <sm-label :label="$t('export.automatic_export.modal.scheduled')" />
          <div
            v-auto-animate
            class="mb-6 space-y-2">
            <div
              v-for="(exportType, index) in scheduledExports"
              :key="index"
              class="flex items-center justify-between w-full">
              <button
                class="px-2.5 py-2 border border-bo-gray-200 rounded-lg leading-none flex items-center "
                @click="removeExport(exportType)">
                {{ newExportOptions.find(data => data.id === exportType.schedule).label }}
                <vue-feather
                  type="x"
                  class="w-4 h-4 ml-1.5 text-black" />
              </button>
              <p>
                <span
                  v-for="(filter, labelIndex) in exportType.labels"
                  :key="filter.label"
                  v-tippy="{ content: `${filter.field}${filter.label}` }">
                  {{ filter.field }}<span v-if="labelIndex + 1 < exportType.labels.length">, </span>
                </span>
              </p>
            </div>
          </div>
        </template>
      </div>

      <sm-select
        v-model="form.automatic_export"
        :label="$t('export.automatic_export.modal.fields.new_export.label')"
        :options="newExportOptions" />

      <div v-auto-animate>
        <sm-alert
          v-if="success"
          color="success"
          after-icon="check-circle">
          <span>
            <strong>{{ $t('export.automatic_export.modal.info[0]') }}</strong>
            {{ $t('export.automatic_export.modal.info[1]') }}
          </span>
        </sm-alert>
      </div>

      <footer
        class="flex flex-wrap items-center justify-end p-4 mt-4 -mx-4 -mb-4 space-x-2 border-t border-brand-gray-200 rounded-b-md">
        <sm-button
          color="secondary"
          :title="$t('generic.cancel')"
          @click="toggleExportModal" />
        <sm-button
          :disabled="!form.automatic_export"
          color="primary"
          :title="$t('export.automatic_export.modal.fields.submit')"
          @click="onSubmit" />
      </footer>
    </sm-modal>
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import axios from "axios";
import { watch } from "vue";

const page = usePage();

const props = defineProps({
  exportRoute: { type: String, required: true, default: "" },
  exportFinished: { type: Boolean, required: false, default: false }
});

const emits = defineEmits(["exportData"]);

const exportModal = ref(false);
const toggleExportModal = () => {
  exportModal.value = !exportModal.value;
};
const scheduledExports = ref([].concat(page.props.existingExports));

const exportButtonOptions = computed(() => [
  {
    id: 1,
    label: window.trans("export.automatic_export.dropdown.download_now"),
    action: () => emits("exportData"),
    icon: "download"
  },{
    id: 2,
    label: window.trans("export.automatic_export.dropdown.automaize_export"),
    action: () => toggleExportModal(),
    icon: "clock",
    badge: { label: scheduledExports.value.length, type: "info" },
  }
]);

const form = useForm({
  automatic_export: null
});
provide("form", form);

const success = ref(false);

const newExportOptions = ref([
  {
    id: "daily",
    label: "Naponta"
  },
  {
    id: "first_day_of_the_week",
    label: "Hét első napja"
  },
  {
    id: "first_day_of_the_month",
    label: "Hónap első napja"
  },
  {
    id: "first_day_of_the_year",
    label: "Év első napja"
  }
]);

const removeExport = async (scheduledExport) => {
  console.log(scheduledExport);
  if (scheduledExport?.id) {
    await axios.delete(scheduledExport.routes.destroy)
      .then(() => {
        scheduledExports.value = scheduledExports.value.filter(data => data !== scheduledExport);
      });
  }

  // TODO: if the export has an id, call api
};

watch(() => props.exportFinished, (value) => {
  if (value === true) {
    onSuccess();
  }
});

const onSuccess = () => {
  scheduledExports.value = [].concat(page.props.existingExports);
  form.reset("automatic_export");
  success.value = true;
};

const onSubmit = () => {
  emits("exportData", {
    automatic_export: form.automatic_export.id,
  });
};
</script>
