<template>
  <div>
    <h4 class="mb-4">
      {{ $t('sales.settings.title') }}
    </h4>

    <sm-card
      :form="form"
      :header-title="$t('sales.settings.title')"
      @onSubmit="onSubmit">
      <div class="w-4/12 mb-4">
        <sm-input
          v-model="state.shift_report_email"
          name="shift_report_email"
          :label="$t('sales.settings.shift_report_email.label')"
          :placeholder="$t('sales.settings.shift_report_email.placeholder')"
          :help="$t('sales.settings.shift_report_email.help')"
          :errors="v$.shift_report_email.$errors"
          type="email"
        >
          <template #afterInput>
            <sm-button
              icon="plus"
              color="primary"
              class="ml-4"
              size="input"
              :disabled="v$.$invalid"
              @click="addEmail" />
          </template>
        </sm-input>
        <div
          v-if="emailIsInTheList"
          class="-mt-4 sm-feedback error">
          {{ $t('sales.settings.shift_report_email.errors.same_email') }}
        </div>
      </div>
      <table class="sm-table">
        <tbody>
          <tr
            v-for="(shiftEmail, index) in computedForm.shift_report_recipients"
            :key="index">
            <td>{{ shiftEmail }}</td>
            <td class="w-px">
              <sm-button
                preset="delete"
                color="danger"
                size="input"
                @click="removeEmail(shiftEmail)"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <hr>

      <div>
        <div class="mt-4 sm-row">
          <div class="w-full lg:w-1/2 sm-col">
            <sm-checkbox
              id="employee_pricing_enabled"
              v-model="computedForm.employee_pricing_enabled"
              name="employee_pricing_enabled"
              :text="$t('generic.yes')"
              :label="$t('sales.settings.employee_pricing_enabled.label')"
              :help="$t('sales.settings.employee_pricing_enabled.help')"
            />
          </div>
          <div class="w-full lg:w-1/2 sm-col">
            <div v-auto-animate>
              <sm-input
                v-if="computedForm.employee_pricing_enabled"
                v-model="computedForm.employee_pricing_percentage"
                name="employee_pricing_percentage"
                :label="$t('sales.settings.employee_pricing_percentage.label')"
                input-classes="w-32"
              >
                <template #append>
                  %
                </template>
              </sm-input>
            </div>
          </div>
        </div>
        <sm-radio-group
          v-model="computedForm.exclude_invoiced_transactions_from_shift_report"
          name="exclude_invoiced_transactions_from_shift_report"
          :items="exclude_invoiced_transactions_from_shift_report_options"
          :label="$t('sales.settings.exclude_invoiced_transactions_from_shift_report.label')"
          :help="$t('sales.settings.exclude_invoiced_transactions_from_shift_report.help')"
          break-lines
        />
        <sm-radio-group
          v-model="computedForm.accessory_only_for_checked_in_customers"
          name="accessory_only_for_checked_in_customers"
          :items="accessory_only_for_checked_in_customers_options"
          :label="$t('clubs.settings.sales.accessory_only_for_checked_in_customers')"
          break-lines
        />
        <sm-radio-group
          v-model="computedForm.shift_report_cash_register_all_day_cash_flow"
          name="shift_report_cash_register_all_day_cash_flow"
          :items="shift_report_cash_register_all_day_cash_flow_options"
          :label="$t('clubs.settings.sales.shift_report_cash_register_all_day_cash_flow')"
          break-lines
        />
        <sm-radio-group
          v-model="computedForm.show_service_fee_field"
          name="show_service_fee_field"
          :items="show_service_fee_field_options"
          :label="$t('clubs.settings.sales.show_service_fee_field')"
          break-lines
        />
        <sm-radio-group
          v-model="computedForm.first_purchase_in_club"
          name="first_purchase_in_club"
          :items="first_purchase_in_club_field_options"
          :label="$t('clubs.settings.sales.first_purchase_in_club')"
          break-lines
        />
        <sm-input
          v-model="computedForm.minimum_balance_topup_amount"
          name="minimum_balance_topup_amount"
          :label="$t('clubs.settings.sales.minimum_balance_topup_amount')"
        />
        <sm-radio-group
          v-model="computedForm.ticket_gifting_enabled"
          name="ticket_gifting_enabled"
          :items="ticket_gifting_enabled_options"
          :label="$t('clubs.settings.sales.ticket_gifting_enabled')"
          break-lines
        />
      </div>

      <template #footer>
        <sm-button
          preset="save"
          :loading="computedForm.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </div>
</template>

<script setup>
import { useFormatArrayToTrueFalseObjects } from "@/inertia/composables/global";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

const props = defineProps({
  form: { type: Object, required: true, default: null }
});

const emit = defineEmits(["onSubmit"]);

const computedForm = computed(() => {
  return props.form;
});

const exclude_invoiced_transactions_from_shift_report_options = useFormatArrayToTrueFalseObjects(["include_invoiced_transactions_from_shift_report", "exclude_invoiced_transactions_from_shift_report"], null, [window.trans("sales.settings.exclude_invoiced_transactions_from_shift_report.options.yes"), window.trans("sales.settings.exclude_invoiced_transactions_from_shift_report.options.no")]);
const accessory_only_for_checked_in_customers_options = useFormatArrayToTrueFalseObjects(["accessory_only_for_checked_in_customers", "accessory_for_everyone"]);
const shift_report_cash_register_all_day_cash_flow_options = useFormatArrayToTrueFalseObjects(["shift_report_all_day", "shift_report_not_all_day"]);
const show_service_fee_field_options = useFormatArrayToTrueFalseObjects(["show_service_fee_field_no", "show_service_fee_field_yes"]);
const first_purchase_in_club_field_options = useFormatArrayToTrueFalseObjects(["first_purchase_in_club_no", "first_purchase_in_club_yes"]);
const ticket_gifting_enabled_options = useFormatArrayToTrueFalseObjects(["ticket_gifting_enabled_no", "ticket_gifting_enabled_yes"]);

// build the custom elements of the form
const state = reactive({
  shift_report_email: ""
});

// custom validation rules
const rules = {
  shift_report_email: { required, email }
};
const emailIsInTheList = ref(false);
const v$ = useVuelidate(rules, state);

const addEmail = () => {
  emailIsInTheList.value = false;
  v$.value.$validate();
  if (computedForm.value.shift_report_recipients.includes(state.shift_report_email)) {
    emailIsInTheList.value = true;
  } else if (v$.value.shift_report_email.$errors.length === 0) {
    computedForm.value.shift_report_recipients.push(state.shift_report_email);
    state.shift_report_email = "";
  }
};

const removeEmail = (email) => {
  computedForm.value.shift_report_recipients = computedForm.value.shift_report_recipients.filter(e => e !== email);
};

const onSubmit = () => {
  emit("onSubmit");
};

</script>
