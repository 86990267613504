export function useGenerateRandomNumbers(items, max) {
  const data = [];
  for (let i = 0; i < items; i++) {
    data.push(Math.floor(Math.random() * max));
  }
  return data;
}

export function useGenerateRandomColor(opacity = false) {
  if (!opacity) {
    return `#${(Math.random() * 0xFFFFFF << 0).toString(16)}`;
  } else {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}

export function useRandomTailwindColor(index) {
  const colors = [
    [62,151,255],
    [101,235,170],
    [247,201,72],
    [138,119,255],
    [0,195,255],
    [255,176,136],
    [98,244,235],
    [252,229,136],
    [180,168,255],
    [203,210,217],
  ];

  const opacityLevel = 1 - (Math.floor(index / colors.length) * 0.1);

  const color = colors[index % colors.length];
  return `rgba(${color.join(",")}, ${opacityLevel})`;
}

export function useGenerateGradient(ctx, chartArea) {
  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, "#3E97FF10");
    gradient.addColorStop(1, "#3E97FF50");
  }

  return gradient;
}

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach(item => {
      const li = document.createElement("li");

      li.onclick = () => {
        const { type } = chart.config;
        if (type === "pie" || type === "doughnut") {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.backgroundColor = (item.strokeStyle !== "transparent" && item.strokeStyle
        !== "#fff") ? item.strokeStyle : item.fillStyle;

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      // Text
      const dataContainer = document.createElement("p");
      if (chart.config.type === "doughnut") {
        dataContainer.style.textDecoration = item.hidden ? "line-through" : "";
        const dataSet = chart.config.data.datasets[0].data;
        const append = ul.parentElement.parentElement.dataset.append;
        const data = document.createTextNode(`${append === "Ft" ? Intl.NumberFormat("hu-HU").format(dataSet[item.index]) + " " : dataSet[item.index]}${append}`);
        dataContainer.appendChild(data);
      }

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      li.appendChild(dataContainer);
      ul.appendChild(li);
      ul.classList.add("legend-container");
    });
  }
};
