<template>
  <div v-auto-animate>
    <div
      v-if="active"
      class="sm-alert"
      :class="[`sm-alert-${color}`, {'mb-0': noMargin}]">
      <p
        :class="{'flex items-center justify-between': !noFlex}">
        <slot />

        <vue-feather
          v-if="afterIcon !== ''"
          :type="afterIcon"
          class="w-4 min-w-[1rem]" />
      </p>
      <div
        v-if="closeable"
        class="absolute transition-colors cursor-pointer top-4 right-4 hover:text-bo-red"
        @click="removeAlert">
        <div class="flex items-center">
          <vue-feather
            type="x"
            class="w-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  color: { type: String, required: false, default: "default" },
  closeable: { type: Boolean, required: false, default: false },
  autoClose: { type: [Boolean, Number], required: false, default: false },
  noMargin: { type: Boolean, required: false, default: false },
  noFlex: { type: Boolean, required: false, default: false },
  afterIcon: { type: String, required: false, default: "" },
});

const active = ref(true);

const removeAlert = () => {
  active.value = !active.value;
};

const counter = ref(typeof props.autoClose === "number" ? props.autoClose : 5);

if (props.autoClose) {

  const countdwn = setInterval(function () {
    counter.value--;
    if (counter.value === 0) {
      active.value = false;
      clearInterval(countdwn);
    }
  }, 1000);
}

</script>
