<template>
  <sm-card
    :header-title="title"
  >
    <div class="pt-2.5">
      <div class="relative flex items-center justify-center">
        <div class="w-[151px] relative mb-6">
          <div
            v-if="summary"
            class="pie-inside-data">
            <p>
              <strong>{{ summary.quantity }}</strong><br>
              {{ summary.subtitle }}
            </p>
          </div>
          <Doughnut
            v-if="!loading"
            :options="chartOptions"
            :data="chartData"
            height="150"
            width="150"
            class="relative"
            :plugins="[htmlLegendPlugin]"
          />
          <div
            v-else
            class="w-[150px] h-[150px] rounded-full border-gray-200 border-[18px] animate-pulse" />
        </div>
      </div>
      <div
        class="pie-chart"
        :data-append="subtitle">
        <div id="actual-usage-chart-legend-container" />
      </div>
    </div>
  </sm-card>
</template>

<script setup>
import { htmlLegendPlugin } from "@/inertia/composables/charts";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import {
  Doughnut
} from "vue-chartjs";

const props = defineProps({
  title: { type: String, required: true, default: null },
  labels: { type: Array, required: true, default: null },
  data: { type: Array, required: true, default: null },
  summary: { type: Object, required: true, default: null },
  subtitle: { type: String, required: true, default: null },
  colors: { type: Array, required: true, default: null },
  loading: { type: Boolean, required: false, default: false },
});

ChartJS.register(
  ArcElement, Tooltip, Legend
);

const chartData = computed(() => ({
  labels: props.labels,
  datasets: [
    {
      backgroundColor: props.colors,
      data: props.data,
      borderWidth: 0,
      weight: 0.1,
      cutout: "75%"
    }
  ]
}));

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    htmlLegend: {
      containerID: "actual-usage-chart-legend-container",
    },
    legend: {
      display: false,
    },
  },
});
</script>
