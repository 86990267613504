<template>
  <template v-if="props.iconPosition === 'left'">
    <vue-feather
      v-if="props.icon"
      :type="props.icon"
      class="inline min-w-fit" />
    <vue-feather
      v-if="props.preset !== '' && presetIcons !== ''"
      :type="presetIcons"
      class="inline min-w-fit" />
  </template>
  <span
    v-if="computedTitle || props.mobileTitle"
    class="inline-flex items-center"
    :class="{'lg:hidden block': props.mobileTitle}">
    {{ computedTitle || props.mobileTitle }}
    <span
      v-if="props.hotkey !== ''"
      class="ml-1 font-bold">({{ props.hotkey }})</span>
    <transition name="loader">
      <span
        v-if="props.loading"
        class="inline-flex">
        <vue-feather
          type="rotate-cw"
          class="inline ml-1 animate-spin" />
      </span>
    </transition>
  </span>
  <vue-feather
    v-if="props.icon && props.iconPosition === 'right'"
    :type="props.icon"
    class="inline" />
</template>

<script setup>
const props = inject("props");
const presetIcons = inject("presetIcons");
const computedTitle = inject("computedTitle");
</script>
