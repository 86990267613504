<template>
  <div>
    <In-Head :title="$t('reports.expiring_tickets.title')" />

    <sub-header
      :title="$t('reports.expiring_tickets.title')"
      :export-route="$page.props.routes.export"
      automatic-export
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <full-line-chart
      :title="$t('reports.expiring_tickets.total')"
      :chart-data="chartData"
      :loading="!$page.props.table"
    />

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.expiring_tickets.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template
                v-else-if="col === 'valid_to'">
                {{ slotProps.data?.valid_to ? useDateFormat(slotProps.data?.valid_to, 'long') : '' }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { useRandomTailwindColor } from "@/inertia/composables/charts";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["customer", "email", "phone", "customer_groups", "name", "valid_to"];

const chartData = computed(() => {
  const stats = props.table?.stats ?? [];

  const sortedStats = [].concat(stats).sort(
    (a, b) => b.ticket_count - a.ticket_count
  );

  const values = sortedStats.map(i => parseInt(i.ticket_count));

  return {
    title: window.trans("reports.expiring_tickets.total"),
    labels: sortedStats.map(s => s.name),
    data: values,
    colors: sortedStats.map((s, i) => useRandomTailwindColor(i)),
    subtitle: " db",
    summary: {
      quantity: values.reduce((sum, acc) => sum += acc, 0),
      subtitle: "db"
    }
  };
});
</script>
